<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>组织架构</el-breadcrumb-item>
      <el-breadcrumb-item>职级管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 列表数据 -->
      <el-table :data="userlest" border stripe>
        <el-table-column label="员工编号" prop="staff_code" align="center"></el-table-column>
        <el-table-column label="姓名" prop="user_name" align="center"></el-table-column>
        <el-table-column label="岗位" prop="job" align="center"></el-table-column>
        <el-table-column label="年龄" prop="age" align="center"></el-table-column>
        <el-table-column label="所属公司" prop="company" align="center"></el-table-column>
        <el-table-column label="工作地点" prop="city" align="center"></el-table-column>
        <el-table-column label="当前状态" prop="status" align="center"></el-table-column>
        <el-table-column label="入职时间" prop="onboarding" align="center"></el-table-column>
        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-s-tools" @click="setrank(scope.row.user_id)"></el-button>
            <!-- <el-button size="mini" type="success" icon="el-icon-view" @click="lookrank(scope.row.user_id)"></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="quertInfo.pagenum"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="quertInfo.pagesize"
        layout="total, sizes, prev, pager, next"
        :total="count"
      ></el-pagination>
    </el-card>
    <!-- 弹窗 -->
    <el-dialog title="设置职级" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="员工名称" label-width="150px">
          <el-input v-model="form.user_name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="选择职级" label-width="150px" prop="role_level">
          <el-select v-model="form.role_level" placeholder="请选择职级" clearable>
            <el-option v-for="item in options" :key="item.tag" :label="item.name" :value="item.tag"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="岗位级别" label-width="150px" prop="manage_role">
          <el-radio-group v-model="form.manage_role">
            <el-radio :label="0">普通员工</el-radio>
            <el-radio :label="1">管理人员</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="sends(form)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { GetPersonnelList, Orglevels, Orgroleset, Orgroleinfo } from '@/api/personnel'
export default {
  data() {
    return {
      dialogVisible: false,
      quertInfo: {
        pagenum: 1,
        pagesize: 10
      },
      userlest: [],
      count: 0,
      form: {
        user: '',
        role_level: '',
        manage_role: '',
        user_name: ''
      },
      options: [],
      rules: {
        manage_role: [{ required: true, message: '请选择岗位级别', trigger: 'change' }],
        role_level: [{ required: true, message: '请选择职级', trigger: 'change' }]
      }
    }
  },
  created() {
    this.getUserList()
    this.Orglevelss()
  },
  methods: {
    sends() {
      this.$refs['form'].validate(v => {
        if (v) {
          // console.log(v)
          this.Orgrolesets()
        }
      })
    },

    // 设置职级
    setrank(user) {
      this.Orgroleinfos(user)
      // console.log(user)
      this.form.user = user
      this.dialogVisible = true
    },

    // 查看设置
    lookrank(user) {
      this.dialogVisible = true
      this.Orgroleinfos(user)
      // console.log(user)
    },

    handleClose() {
      this.dialogVisible = false
      this.form.user = ''
      this.form.user_name = ''
      this.form.role_level = ''
      this.form.manage_role = ''
    },

    // 监听pagingtion改变
    handleSizeChange(newSize) {
      this.quertInfo.pagesize = newSize
      this.getUserList()
    },
    // 监听页码值改变事件
    handleCurrentChange(newPape) {
      this.quertInfo.pagenum = newPape
      this.getUserList()
    },

    async getUserList() {
      // 获取列表数据
      const { data } = await GetPersonnelList(this.quertInfo)
      if (data.code !== 200) {
        return this.$message.error('获取用户列表失败')
      }
      this.userlest = data.data.list // 赋值
      this.count = data.data.count // 总条数
    },

    // 获取职级列表
    async Orglevelss() {
      const { data } = await Orglevels()
      // console.log(data.data, '职级列表')
      this.options = data.data
    },

    // 获取职级详情
    async Orgroleinfos(user) {
      const { data } = await Orgroleinfo({ user: user })
      // console.log(data)
      this.form.user_name = data.data.user_name
      this.form.role_level = data.data.role_level
      this.form.manage_role = data.data.manage_role
    },

    // 设置职级
    async Orgrolesets() {
      const { data } = await Orgroleset({ ...this.form })
      // console.log(data)
      if (data.code == 200) {
        this.$message({
          message: '设置职级成功',
          type: 'success'
        })
        this.handleClose()
      } else {
        this.$message({
          message: `${data.message}`,
          type: 'error'
        })
      }
    }
  }
}
</script>
<style lang="less" scope>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}
.yuan {
  position: absolute;
  margin-left: 10px;
}
.manpaodding {
  .el-form-item .yinput,
  .el-date-editor {
    width: 80%;
  }
  .el-form-item .yinput {
    width: 84%;
  }
}
</style>
